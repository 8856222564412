import axios from "axios";
import Utils from '../utils';


const AxiosApp = axios.create({});

AxiosApp.interceptors.request.use(async config => {

  const headers = Utils.getHeaders();

  config.headers = headers;

  return config
}, error => {
  return Promise.reject(error);
});

export default AxiosApp;