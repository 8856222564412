import React from 'react';
import { ThemeProvider } from 'styled-components';
import { AuthContextProvider } from './contexts/auth';
import { ToastProvider } from './contexts/toast';
import Rotas from './routes';
import { GlobalStyle } from './styles/GlobalStyles';
import Defaulttheme from './styles/themes/defaulttheme';
import { CallMethodProvider } from "./contexts/callmethods"
import { TurnoProvide } from "./contexts/turno"
import { CustumerProvide } from "./contexts/custumers"
import { DataDashProvide } from "./contexts/dash"

function App() {
  return (
    <ThemeProvider theme={Defaulttheme}>
      <GlobalStyle />
      <AuthContextProvider>
        <CallMethodProvider>
          <TurnoProvide>
            <CustumerProvide>
              <DataDashProvide>
                <ToastProvider>
                  <Rotas />
                </ToastProvider>
              </DataDashProvide>
            </CustumerProvide>
          </TurnoProvide>
        </CallMethodProvider>
      </AuthContextProvider>

    </ThemeProvider>
  );
}

export default App;
