import React, { createContext, useState } from "react";
import { ITurno } from "../@interfaces"

interface ITurnoContext {
  turnoDescription: string;
  turnoValue: number;
  allTurnos: ITurno[];
  setCurrentTurno: (value: number | string) => void;
  setAllTurnos: (turnos: ITurno[]) => void;
}


const TurnoContext = createContext<ITurnoContext>({} as ITurnoContext);

export const TurnoProvide: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [turnoValue, setTurnoValue] = useState<number>(0);
  const [turnoDescription, setTurnoDescription] = useState<string>('');
  const [allTurnos, _setAllTurnos] = useState<ITurno[]>([]);


  function setCurrentTurno(codeTurno: number | string) {
    if (allTurnos.length > 0) {
      const turno = allTurnos.filter(i => i.codturno === codeTurno)[0];
      setTurnoValue(turno.codturno);
      setTurnoDescription(turno.descricao);
    }
  }

  function setAllTurnos(turnos: ITurno[]) {
    _setAllTurnos(turnos);
    const currentTurno = turnos[0];
    setTurnoValue(currentTurno.codturno);
    setTurnoDescription(currentTurno.descricao);
  }

  return (
    <TurnoContext.Provider value={{ turnoDescription, turnoValue, setCurrentTurno, allTurnos, setAllTurnos }}>
      {children}
    </TurnoContext.Provider>
  )
}

export default TurnoContext;