import { useCallback, useContext, useEffect, useRef, useState } from "react";
import useVideoChat from "../../hooks/useVideoChat";
import Home from "../../pages/home";
import InCallScreen from "../../pages/incallscreen";
import QrCodeDecoder from 'qrcode-decoder';
import storage from "../../storage"
import CallMethodContext from "../../contexts/callmethods";
import ApiUser from "../../services/ApiUser";
import moment from "moment";
import { ISectionCall } from "../../@interfaces";
import CustumerContext from "../../contexts/custumers";


const localAdress = 'https://apidemo.grupohk.com.br:5051'
const serverProduction = 'https://api.grupohk.com.br:5005/'


const Content: React.FC = () => {
  const [onCall, setOnCall] = useState<boolean>(false);
  const [bootstrap, mySocketID, remoteStream, localStream, callingme, inCall, callUser, acceptACall, endCall, readQrCodeRemote, takeRemotePictures, refuseCall, socketInstance, connectionState] = useVideoChat();
  const { setShowRaiting, userReCalled, setInCall, showRaiting, setCallingState, callingState, setTryingReconnect } = useContext(CallMethodContext);
  const { getCustumerListFromApi } = useContext(CustumerContext)
  const timer = useRef<any>(null);
  const timerStartCalling = useRef<any>(null);
  const dateInitCalling = useRef<any>(null);
  const refCallingState = useRef<any>('');



  useEffect(() => {
    initStream();
  }, []);

  useEffect(() => {
    refCallingState.current = callingState;
  }, [callingState])


  useEffect(() => {
    if (remoteStream) {
      setInCall(true);
      setOnCall(true);
    }
    else {
      setOnCall(false);
      setInCall(false);
    }
  }, [remoteStream])




  useEffect(() => {
    console.log(mySocketID)
  }, [mySocketID])


  useEffect(() => {
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    }
  }, [])





  const initStream = useCallback(async () => {
    const _token = storage.getLicenseToken();
    const _RTCIceCandidate = RTCIceCandidate;
    const _RTCPeerConnection = RTCPeerConnection;
    const _RTCSessionDescription = RTCSessionDescription;
    const objConfig = {
      _RTCIceCandidate,
      _RTCPeerConnection,
      _RTCSessionDescription,
      _isMobile: false,
      _utilsGetUserMedia: navigator.mediaDevices.getUserMedia,
      _token,
      _socketAdress: process.env.REACT_APP_URL_API_SOCKET,
      _audioOnStream: true,
      _QRCodeDecodeLibWeb: QrCodeDecoder,
      _callBackInitCall: callBackInitCall,
      _callBackEndCall: callBackEndCall,
      _onAbleToReceiveCall: ableToReceivecall,
      _onAbleToReconnect: handleAbleToReconnect
    }

    if (bootstrap && typeof bootstrap === 'function') await bootstrap(objConfig); //CHECAR SE TEM OUTRA SOLUÇÃO PRA ISSO
  }, [])





  const ableToReceivecall = async (obj: { smartID: string }) => {
    if (refCallingState.current !== 'none') {
      setCallingState("Estabelecendo conexão ...");
      startCall(obj.smartID);
    }
  }



  const handleAbleToReconnect = async (obj: { smartID: string }) => {
    debugger
    setTryingReconnect(true);
    setCallingState("Reconectando ...");
    //@ts-ignore
    callUser(obj.smartID);
  }



  async function callBackEndCall() {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }

    if (timerStartCalling.current) {
      clearInterval(timerStartCalling.current);
      timerStartCalling.current = null;
    }

    getCustumerListFromApi();

    if (!showRaiting)
      setShowRaiting(true);

    setTryingReconnect(false);
  }





  const callBackInitCall = async () => {
    try {

      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
      if (timerStartCalling.current) {
        clearInterval(timerStartCalling.current);
        timerStartCalling.current = null;
      }

      timer.current = setInterval(() => {
        const { chaveExec } = storage.getSectionCall();
        ApiUser.sendChamadaAtiva(Number(chaveExec));
      }, 60000 * 15);

      setTryingReconnect(false);
    } catch (error) {
      throw new Error(`callBackInitCall ${error}`);
    }
  }




  async function startCall(id: string) {
    if (typeof callUser === 'function') {
      // Inicia Supervisão
      const sectionCall = storage.getSectionCall();


      const progSupRemota = sectionCall.custumer.progSupRemota

      const response = await ApiUser.iniciaSupervisao(progSupRemota);

      if (response.error) return;

      if (response.chaveExecucao) {
        const updateSectionCall: ISectionCall = {
          ...sectionCall,
          chaveExec: response.chaveExecucao
        }

        storage.setSectionCall(updateSectionCall);

        callUser(id);

        dateInitCalling.current = moment();

        timerStartCalling.current = setInterval(() => {
          const spendTime = moment().diff(dateInitCalling.current, "seconds")

          if (spendTime > 120) {
            finishNotCompleteCall();
          }
        }, 1000)
      }
    }
  }





  async function finishNotCompleteCall() {
    finishCall();
    const { chaveExec } = storage.getSectionCall();
    ApiUser.finalizaSupervisaoRemota({
      chaveExecucao: chaveExec,
      situacaoChamada: 5
    })
  }





  function finishCall() {
    if (endCall && typeof endCall === 'function') endCall();
    setOnCall(false);
    if (!showRaiting)
      setShowRaiting(true);
  }






  return onCall ? (
    <InCallScreen
      finishCall={finishCall}
      localStream={localStream}
      remoteStream={remoteStream}
      takePicture={takeRemotePictures}
      readQrCode={readQrCodeRemote}
      connectionState={connectionState}
    />
  ) : (
    <Home
      startCall={startCall}
      pending={!!localStream && !remoteStream}
      finishCall={finishCall}
      socketID={mySocketID}
    />
  )

}




export default Content;