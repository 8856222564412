import { useContext, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { IPasswordRecoveryProps } from "../../../../@interfaces"
import { Answer, AnswerOption, RecoveryQuestion } from "../../../../@types";
import ModalBase from "../../../../components/modalbase"
import ToastContext from "../../../../contexts/toast";
import APIAuth from "../../../../services/APIAuth";
import storage from "../../../../storage";
import { Container, NavButton, NavOptions, Option, OptionsContainer, Question, Warning } from "./styles";

const RecoveryQuestions: React.FC<IPasswordRecoveryProps> = ({ closeModal, cpf, handleSuccess }) => {
  const [questions, setQuestions] = useState<RecoveryQuestion[]>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [phone, setPhone] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { showSuccessToast, showErrorToast } = useContext(ToastContext);

  useEffect(() => {
    loadQuestions();
  }, []);


  
  
  async function loadQuestions() {
    try{
      setIsLoading(true);
      const response = await APIAuth.getRecoveryQuestions(cpf.replace(/[.-]/g, ''));

      if(!response.error && response.perguntas){
        setQuestions(response.perguntas);
        setPhone(response.telefone_admpessoal);
      }else{
        showErrorToast(response.message);
        closeModal();
      }
    }catch{
      showErrorToast("Algo deu errado");
      closeModal();
    }finally{
      setIsLoading(false);
    }

  }




  function selectOption(id: number){
    let aux = questions;    
    const option = aux![currentIndex].alternativas.find(item => item.id === id);
    const otherOptions = aux![currentIndex].alternativas.filter(item => item.id !== option?.id)

    for(const item of otherOptions){
      if(item.selected) item.selected = false;
    }

    option!.selected = !option?.selected;
    setQuestions([...aux!]);
  }




  function goToNextQuestion(){
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  


  function goToPreviousQuestion(){
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
  }




  async function handleSubmit(){
    const answers = questions!.map(item => {
      const id = item.idpergunta;
      const option = item.alternativas.find(item => item.selected);
      return {
        id,
        texto: option? option.texto : ''
      }
    })

    try {
      setIsSubmitting(true)
      const body = {
        cpf: cpf.replace(/[.-]/g, ''),
        respostas: answers
      }

      const response = await APIAuth.sendRecoveryAnswers(body);
      if(!response.error && response.token_acesso){
        storage.setAccessToken(response.token_acesso);
        handleSuccess();
      }else{
        showErrorToast(response.message);
      }
    }catch{
      showErrorToast('Algo deu errado')
    }finally{
      setIsSubmitting(false)
    }
  }


  
  
  return(
    <ModalBase closeModal={closeModal} width={"500px"}>
      
      <Container>
        {questions && !isLoading &&
        <>
        <Question>
          {questions![currentIndex].pergunta}
        </Question>

        <OptionsContainer>
          {questions![currentIndex].alternativas.map(item =>
            <Option onClick={() => selectOption(item.id)} selected={item.selected}>
              {item.texto}
            </Option>
            
          )}
        </OptionsContainer>

        <NavOptions>
          {currentIndex > 0 &&
            <NavButton onClick={goToPreviousQuestion}>
              Voltar
            </NavButton> 
          }

          {!!questions![currentIndex].alternativas.find(item => item.selected) && questions.length > currentIndex + 1 &&
            <NavButton onClick={goToNextQuestion} primary>
              Próxima
            </NavButton> 
          }

          {!!questions![currentIndex].alternativas.find(item => item.selected)  && questions.length === currentIndex + 1 &&
            <NavButton onClick={handleSubmit} primary disabled={isSubmitting}>
              {isSubmitting ? <TailSpin color="#000" width={15} height={15}/>: 'Avaliar respostas'}
            </NavButton>       
          }  

        </NavOptions>

        <Warning>Caso nenhuma alternativa esteja correta, por favor, entre em contato com sua empresa no seguinte número: {phone}</Warning>

        </>}
        {isLoading && <TailSpin color="#2991D6"/>}
      </Container>
    </ModalBase>
  )
}

export default RecoveryQuestions;