import React, { createContext, useState, useContext } from "react";
import { IDataDashContext } from "../@interfaces"
import TurnosContext from "./turno"
import ApiUser from "../services/ApiUser"

import { useTheme } from "styled-components"

interface IDashContext {
  dataDash: IDataDashContext | null,
  getDataDash: () => void;
}



const DataDashContext = createContext<IDashContext>({} as IDashContext);
const defaultInfo = {
  chamadasGerais: {
    chamadasDia: 0,
    chamadasEfetivadas: 0,
    chamadasPerdidas: 0,
  },

  chamadasOperador: {
    chamadasDia: 0,
    chamadasEfetivadas: 0,
    chamadasPerdidas: 0,
  },
  charts: null
}

export const DataDashProvide: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [dataDash, setDataDash] = useState<IDataDashContext>(defaultInfo);


  const theme = useTheme();

  const { turnoValue } = useContext(TurnosContext);

  async function getDataDash(): Promise<IDataDashContext> {
    try {
      setDataDash(defaultInfo);
      const _data = defaultInfo;

      const response = await ApiUser.getIndicadores(turnoValue);


      let objDataCharts: any = null;

      if (!response.error) {
        const { cincoMinutos, trintaMinutos, quinzeMinutos, sessentaMinutos, sessentaMinutosMais } = response.medias!;

        if (response.chamadasGerais) _data.chamadasGerais = response.chamadasGerais;
        if (response.chamadasOperador) _data.chamadasOperador = response.chamadasOperador;


        if (cincoMinutos || trintaMinutos || quinzeMinutos || sessentaMinutos || sessentaMinutosMais) {
          objDataCharts = {
            labels: [],
            datasets: [
              {
                data: [cincoMinutos, quinzeMinutos, trintaMinutos, sessentaMinutos, sessentaMinutosMais],
                backgroundColor: [
                  theme.colors.primary,
                  theme.colors.green,
                  theme.colors.yellow,
                  theme.colors.red,
                  theme.colors.redBlack,
                ],
                borderWidth: .4,
              }
            ]
          }
        }
      }

      const result = {
        chamadasGerais: _data.chamadasGerais,
        chamadasOperador: _data.chamadasOperador,
        charts: objDataCharts
      }

      setDataDash(result)

      return result;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  }


  return (
    <DataDashContext.Provider value={{ getDataDash, dataDash }}>
      {children}
    </DataDashContext.Provider>
  )
}

export default DataDashContext;