import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Container,
  Content,
  ItensContainer,
  Footer,
  ContainerPics,
  PicContainer
} from './styles';

import { IAttachs } from "../../../../../@interfaces"
import { IoTrashBin } from "react-icons/io5"

interface IProps {
  close: () => void;
  handleAttach?: () => void;
  imgs?: IAttachs[] | null | [],
  deleteAttach: (param: number) => void;
}

const Photos: React.FC<IProps> = ({ close, imgs, handleAttach, deleteAttach }) => {


  return (
    <Container>
      <Content>
        <ItensContainer>
          <ContainerPics>
            {(!imgs || imgs.length === 0) && <span>Nenhuma foto anexada</span>}
            {
              imgs?.map(i => {
                return <PicContainer>
                  <img src={i.base64} alt={"Fotos anexadas"} />
                  <button onClick={() => deleteAttach(i.id)}>
                    <IoTrashBin color={"#FF0100"} size={18} />
                  </button>
                </PicContainer>
              })
            }


          </ContainerPics>
        </ItensContainer>
        <Footer>
          <button id={"addButton"} onClick={handleAttach}>+ Adicionar</button>
          <button onClick={close}>Fechar</button>
        </Footer>
      </Content>
    </Container>
  )
}

export default Photos;

