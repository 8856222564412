import styled from "styled-components"


interface IProps {
  width?: string;
}

export const DropButton = styled.button<IProps>`
  width: 100%;
  padding: 10px;
  outline: none;

  background: ${props => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.colors.placeHolderColor};
  cursor: pointer;

  ${({ width }) => {
    if (width) {
      return `width: ${width}`;
    }
  }}
`