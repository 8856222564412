import React from 'react';

import {
  Container,
  Box,
  Footer,
  Content,
  Header,
  CloseButton,
  Button
} from './styles';


import { IoIosClose } from "react-icons/io"
import { IActionDialog, IDialogProps } from '../../@interfaces';


const Dialog: React.FC<IDialogProps> = ({ close, actions, text, onlyOk }) => {

  function handleOptions(action: IActionDialog) {
    if (close)
      close();
    if (action.handle) {
      action.handle();
    }
  }

  return (
    <Container>
      <Box>
        <Header>
          <CloseButton onClick={close}>
            <IoIosClose size={28} color={"rgba(0,0,0,.7)"} />
          </CloseButton>
        </Header>

        <Content>
          <p>{text}</p>
        </Content>

        {onlyOk && <Footer>
          <Button onClick={close} primary>Ok</Button>
        </Footer>}

        {!onlyOk && (actions && actions.length > 0) && <Footer>
          {
            actions.map(i => {
              return <Button onClick={() => handleOptions(i)} primary={i.primary} >{i.title}</Button>
            })
          }
        </Footer>}
      </Box>
    </Container>
  )
}

export default Dialog;

