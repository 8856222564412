import { useContext, useState } from "react";
import { AiFillCaretDown, AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Button from "../../components/button";
import { CompanyResponse, CompanySelectOption } from "../../@types";
import AuthContext from "../../contexts/auth";
import ToastContext from "../../contexts/toast";
import APIAuth from "../../services/APIAuth";
import storage from "../../storage";
import aux from "../../utils/auxiliar";
import CompanySelectionModal from "./components/companyselectionmodal";
import { useTheme } from "styled-components"
import { Container, ContentContainer, DrawContainer, Dropdown, Field, FieldContainer, FormContainer, PasswordOptionsContainer, PasswordRecoberyLink, PasswordViewButton } from "./styles";
import PasswordRecovery from "./components/recoveryquestions";
import RecoveryQuestion from "./components/recoveryquestions";
import RecoveryQuestions from "./components/recoveryquestions";
import PasswordChange from "./components/passwordchange";

const Login: React.FC = () => {
  const [cpf, setCpf] = useState<string>('');
  const [companyList, setCompanyList] = useState<CompanySelectOption[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanySelectOption>();
  const [password, setPassword] = useState<string>('');

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isCompanySelectionOpen, setIsCompanySelectionOpen] = useState<boolean>(false);
  const [isPasswordFieldVisible, setIsPasswordFieldVisible] = useState<boolean>(false);
  const theme = useTheme();
  const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState<boolean>(false);
  const [isRecoveryQuestionsOpen, setIsRecoveryQuestions] = useState<boolean>(false);



  const { signIn } = useContext(AuthContext);
  const { showErrorToast } = useContext(ToastContext);




  function handleCPFChange(cpf: string) {
    setCpf(aux.cpfMask(cpf));
  }




  async function validCPF() {
    try {
      setIsLoading(true);
      const response = await APIAuth.validCPF(cpf.replace(/[.-]/g, ''));
      if (!response.error) {
        await getCompanies();
      } else {
        showErrorToast(response.message);
      }
    } catch {
      showErrorToast("Algo deu errado");
      return;
    } finally {
      setIsLoading(false);
    }
  }




  async function getCompanies() {
    try {
      const response = await APIAuth.getCompanies();
      if (!response.error && response.empresas) {
        const formattedCompanyList = formatCompanyList(response.empresas);
        setCompanyList(formattedCompanyList);
      }
    } catch {
      return;
    }
  }





  function formatCompanyList(companyList: CompanyResponse[]): CompanySelectOption[] {
    const formatted = companyList.map(item => {
      return {
        label: item.NOMEFANTASIA,
        value: item.CODIGOEMPRESA,
        url: item.URL_API,
      }
    });
    return formatted;

  }




  function openCompanySelection() {
    setIsCompanySelectionOpen(true);
  }




  function handleSelection(company: CompanySelectOption) {
    setSelectedCompany(company);
    setIsCompanySelectionOpen(false);
  }




  function closeCompanySelection() {
    setIsCompanySelectionOpen(false);
  }




  function confirmCompany() {
    if (selectedCompany) storage.setClientUrl(selectedCompany?.url);
    setIsPasswordFieldVisible(true);
  }




  function handlePasswordChange(password: string) {
    setPassword(password);
  }




  function handlePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
  }




  async function handleButtonClick() {
    if (companyList.length === 0) await validCPF();
    if (selectedCompany && !isPasswordFieldVisible) confirmCompany();
    if (isPasswordFieldVisible) handleLogin();
  }



  async function openRecoveryQuestions() {
    setIsRecoveryQuestions(true);
  }




  async function openPasswordChange() {
    setIsPasswordChangeOpen(true);
    setIsRecoveryQuestions(false);
  }




  function closePasswordRecovery() {
    setIsPasswordChangeOpen(false);
    setIsRecoveryQuestions(false);
  }




  async function handleLogin() {
    try {
      setIsLoading(true);
      const user = {
        id: cpf.replace(/[.-]/g, ''),
        password,
        companyCode: selectedCompany?.value || 0
      }

      const response = await APIAuth.validLogin(user);

      if (!response.error) {
        const { token, nome, re } = response;
        if (token) storage.setAccessToken(token);
        if (nome && re) storage.setLoggedUser({ name: nome, re })
        signIn();
      } else {
        showErrorToast(response.message);
      }
    } catch (e) {
      showErrorToast("Algo deu errado")
    } finally {
      setIsLoading(false);
    }
  }





  return (
    <Container>
      {isCompanySelectionOpen && <CompanySelectionModal options={companyList} handleClick={handleSelection} closeModal={closeCompanySelection} />}
      {isRecoveryQuestionsOpen && <RecoveryQuestions closeModal={closePasswordRecovery} cpf={cpf} handleSuccess={openPasswordChange} />}
      {isPasswordChangeOpen && <PasswordChange closeModal={closePasswordRecovery} />}
      <ContentContainer>
        <FormContainer>

          <FieldContainer>
            <h1 id={"welcomeTitle"}>Bem-vindo(a)!</h1>

            <Field placeholder="Digite o cpf" value={cpf} onChange={e => handleCPFChange(e.target.value)} disabled={companyList.length > 0} />

            {companyList.length > 0 &&
              <Dropdown onClick={openCompanySelection} slideDirection="left">
                {selectedCompany ? selectedCompany.label : 'Escolha a empresa'}
                <AiFillCaretDown />
              </Dropdown>
            }

            {isPasswordFieldVisible &&
              <PasswordOptionsContainer style={{ position: 'relative', width: '250px' }}>
                <Field placeholder="Digite a senha" type={isPasswordVisible ? "text" : "password"} value={password} onChange={e => handlePasswordChange(e.target.value)} />
                <PasswordViewButton onClick={handlePasswordVisibility}>
                  {isPasswordVisible ? <AiFillEyeInvisible size={20} color={theme.colors.darkSilver} /> : <AiFillEye size={20} color={theme.colors.darkSilver} />}
                </PasswordViewButton>

                <PasswordRecoberyLink onClick={openRecoveryQuestions}>Esqueci a senha</PasswordRecoberyLink>
              </PasswordOptionsContainer>
            }


            <Button
              onClick={handleButtonClick}
              width={250}
              isLoading={isLoading}
              label="Avançar"
              margin={"10px 0 0 0"}
            />
          </FieldContainer>
        </FormContainer>
        <DrawContainer>
          <h1><strong>HK </strong>Supervisão Remota</h1>
          <img src="./assets/undraw_group_video_re_btu7.svg" width={350} height={250} />
        </DrawContainer>
      </ContentContainer>
    </Container>
  )
}

export default Login;