import storage from "../storage";

export default class Utils {
  static fileToBase64(element: any) {
    if (!element) return;
    return new Promise((resolve) => {
      const fileReader = new FileReader();

      var base64data;

      fileReader.readAsDataURL(element.files[0]);

      fileReader.onload = () => {
        base64data = fileReader.result;
        resolve(base64data);
      }
    })
  }


  static saveFileBase64(base64: string, fileName: string) {
    const downloadLink = document.createElement("a");
    downloadLink.href = base64;
    downloadLink.download = fileName;
    downloadLink.click();
  }




  static getHeaders = () => {
    const userJson = {
      "login": storage.getAccessToken(),
      "licenca": storage.getLicenseToken()
    }
    const formattedJson = JSON.stringify(userJson).trim();

    const token = btoa(formattedJson);
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    return headers;
  }




  static calcPercent(hundredPercet: number, value: number): number {
    let result: number = 100;

    if (hundredPercet < value) return 100;

    result = Math.floor((value / hundredPercet) * 100)

    return result;
  }



}