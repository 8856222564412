import React from 'react';

import { Container } from './styles';

const NoData: React.FC = () => {
  return (
    <Container>
      <div id={"noData"}>
        <span>Sem dados a serem exibidos</span>
      </div>
    </Container>
  )
}

export default NoData;