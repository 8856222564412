import React from 'react';
import { TailSpin } from "react-loader-spinner";
import { ContainerButton, RoundButton } from './styles';

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: JSX.Element,
  buttonSize: number;
  label?: string;
  color?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

const RoundIconButton: React.FC<IProps> = ({ icon, buttonSize, label, color, onClick, style, isLoading, disabled }) => {
  return (
    <ContainerButton>
      <RoundButton size={buttonSize} color={color} onClick={onClick} style={style} disabled={isLoading || disabled}>
        {
          !isLoading ? icon : (
            <TailSpin
              height={15}
              width={buttonSize / 2}
              color="#fff"
            />
          )
        }
      </RoundButton>
      {label && <span>{label}</span>}
    </ContainerButton>
  );
}

export default RoundIconButton;