import { CloseButton, Container, DescriptionInput, SelectionLink, SendButton, Title } from "./styles";
import { AiOutlineClose } from 'react-icons/ai';
import { IModalProps } from "../../../../@interfaces";

const OccurenceForm: React.FC<IModalProps> = ({ closeModal }) => {
  return(
    <Container>
      <div>
        <Title>Abertura de Ocorrência</Title>
        <SelectionLink>+ Selecionar motivo</SelectionLink>
      </div>
      <CloseButton onClick={closeModal}>
        <AiOutlineClose color="#fff" size={25} />
      </CloseButton>

      <DescriptionInput placeholder="Digite aqui a descrição da ocorrência..."/>
      <SendButton>
        Enviar
      </SendButton>
    </Container>
  )
}

export default OccurenceForm;