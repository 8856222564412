import styled from "styled-components"


export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
  padding: 20px 0 20px;
`


export const LabelInput = styled.label`

`


export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center; 
  gap: 20px;
  color: ${props => props.theme.colors.white};
`

export const Input = styled.input.attrs({
  placeholder: 'Digite o RE ...'
})`
  width: 60%;
  padding: 12px;
  outline: none;
  border: none;
  background: #fff;
  border: 1px solid #ddd
`