import React from 'react';

import { Container, ToolTip } from './styles';

interface IProps {
  text: string;
  children: JSX.Element;
}

const TooTip: React.FC<IProps> = ({ children, text }) => {
  return (
    <Container>
      {children}
      <div>
        <ToolTip>{text}</ToolTip>
      </div>
    </Container>
  )
}

export default TooTip;