import React, { useEffect, useRef, useState } from 'react'
import io from "socket.io-client"



const useVideoChat = () => {

  const peerConnection = useRef();
  const candidatesToSend = useRef();
  const refRTCSessionDescription = useRef();
  const refRTCPeerConnection = useRef();
  const refRTCIceCandidate = useRef();
  const refAudioOnStream = useRef(false);
  const refMyID = useRef();
  const socket = useRef();
  const refCaller = useRef();
  const refIDTOCall = useRef();
  const refQrCodeDecoder = useRef();
  const refUtilsGetUserMedia = useRef();
  const refCallBackCallUser = useRef();
  const refCallBackEndCall = useRef();
  const refCallBackInitCall = useRef();
  const refOnAbleToReceiveCall = useRef();
  const refOnAbleToReconnect = useRef();
  const refOnAnswerReconnect = useRef();
  const refIsMobile = useRef(false);
  const refStorageLib = useRef(null);
  const [remoteStream, setRemoteStream] = useState();
  const [localStream, setLocalStream] = useState();
  const refLocalStream = useRef();
  const [mySocketID, setMySocketID] = useState();
  const [callingme, setCallingme] = useState();
  const [idOther, setIdOther] = useState(0);
  const dataCalling = useRef();
  const [inCall, setInCall] = useState(false);
  const [adressAndToken, setAdressAndToken] = useState({})
  const [socketInstance, setSocketInstance] = useState(null);
  const [connectionState, setConnectionState] = useState(null);




  /**
   * @param objConfig = {
   *  _RTCSessionDescription, not null
   *  _RTCPeerConnection, not null
   *  _RTCIceCandidate, not null
   *  _QRCodeDecodeLibWeb null,
   *  _isMobile not null
   *  _utilsGetUserMedia onlyMobile is necessary,
   *  _token not null
   *  _socketAdress not null,
   *  _audioOnStream // only web. 
   *  _callBackCallUser
   *  _storageLib 
   *  _callBackEndCall
   *  _callBackInitCall
   *  _onAbleToReceiveCall
   *  _onAbleToReconnect
   *  _onAnswerReconnect
   * }
   */
  async function bootstrap(objConfig) {
    if (socket.current) return;

    const { _onAbleToReconnect, _onAnswerReconnect, _onAbleToReceiveCall, _callBackInitCall, _callBackEndCall, _storageLib, _callBackCallUser, _RTCSessionDescription, _RTCPeerConnection, _RTCIceCandidate, _QRCodeDecodeLibWeb, _utilsGetUserMedia, isMobile, _token, _socketAdress, _audioOnStream } = objConfig;

    refRTCSessionDescription.current = _RTCSessionDescription;
    refRTCPeerConnection.current = _RTCPeerConnection;
    refRTCIceCandidate.current = _RTCIceCandidate;
    refIsMobile.current = isMobile;
    refCallBackCallUser.current = _callBackCallUser

    if (_audioOnStream) refAudioOnStream.current = true
    if (_callBackEndCall) refCallBackEndCall.current = _callBackEndCall;
    if (_callBackInitCall) refCallBackInitCall.current = _callBackInitCall;

    if (_utilsGetUserMedia) refUtilsGetUserMedia.current = _utilsGetUserMedia;
    if (_QRCodeDecodeLibWeb) refQrCodeDecoder.current = _QRCodeDecodeLibWeb;
    if (_onAbleToReceiveCall) refOnAbleToReceiveCall.current = _onAbleToReceiveCall;
    if (_onAbleToReconnect) refOnAbleToReconnect.current = _onAbleToReconnect;
    if (_onAnswerReconnect) refOnAnswerReconnect.current = _onAnswerReconnect;
    if (_storageLib) refStorageLib.current = _storageLib;

    setAdressAndToken({
      adress: _socketAdress, token: _token
    })

    socket.current = io(_socketAdress, {
      query: { token: _token },
      reconnectionDelay: 3000,
      reconnection: true,
      reconnectionAttempts: Infinity,
    });

    //Socket events
    socket.current.on("me", (id) => {
      setMySocketID(id);
      refMyID.current = id;
    });


    socket.current.on("callUser", (data) => {
      handleCallUserEvent(data)
    })


    socket.current.on("callWasRefuse", () => {
      refuseCall()
    })


    socket.current.on("ice-candidate", (data) => {
      addCandidate(data.candidates)
    })

    socket.current.on("endCall", (data) => {
      endCall();
    })


    socket.current.on("callAccepted", (data) => {
      callAnswerd(data)
    })

    socket.current.on("ableToReceiveCall", (data) => {
      if (refOnAbleToReceiveCall.current) {
        refOnAbleToReceiveCall.current(data);
      }
    })


    socket.current.on("ableToReconnect", (data) => {
      if (refOnAbleToReconnect.current) {
        refOnAbleToReconnect.current(data);
      }
    })


    socket.current.on("answerReconnect", (data) => {
      if (refOnAnswerReconnect.current) {
        refOnAnswerReconnect.current(data);
      }
    })


    setSocketInstance(socket.current);
  }



  async function configFramesAndWebrtc() {
    try {
      cleanStreams();
      let _stream = null;
      if (!refIsMobile.current)
        _stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: refAudioOnStream.current });
      else {
        _stream = await refUtilsGetUserMedia.current();
      }
      setLocalStream(_stream);
      refLocalStream.current = _stream;
      configWebRTC(_stream);
    } catch (error) {
      console.log(error)
    }
  }



  function configWebRTC(_streamParamter) {
    try {
      peerConnection.current = null;

      const configuration = {
        "iceServers": [
          {
            urls: 'stun:turn.appgrupohk.com.br'
          },

          {
            url: 'turn:turn.appgrupohk.com.br:443?transport=tcp',
            credential: 'test123',
            username: 'test'
          },
        ],
        iceTransportPolicy: 'all',
        iceCandidatePoolSize: 0,
        rtcpMuxPolicy: 'negotiate'
      };

      peerConnection.current = new refRTCPeerConnection.current(configuration);

      if (_streamParamter) {
        peerConnection.current.addStream(_streamParamter);
      }

      peerConnection.current.onaddstream = (event) => {
        if (event.stream) {
          setRemoteStream(event.stream)
        }
      }

      peerConnection.current.onicecandidate = async (event) => {
        if (!candidatesToSend.current) candidatesToSend.current = [];
        candidatesToSend.current.push(event.candidate);
      }

      peerConnection.current.addEventListener('connectionstatechange', event => {
        setConnectionState(peerConnection.current.connectionState)
      });

    } catch (error) {
      throw new Error('Erro ao configurar webRTC ' + error)
    }
  }






  async function callUser(idToCallParam) {
    try {

      await configFramesAndWebrtc();

      if (peerConnection.current) {

        const offer = await peerConnection.current.createOffer({
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 1
        })

        await peerConnection.current.setLocalDescription(offer);

        refIDTOCall.current = idToCallParam;

        setIdOther(idToCallParam);

        socket.current.emit("callUser", {
          userToCall: idToCallParam,
          offer: offer,
          from: refMyID.current
        })
      }
    } catch (error) {
      console.log(error)
    }
  }




  async function handleCallUserEvent(data) {
    refCaller.current = data.from;
    setIdOther(data.from);
    dataCalling.current = data;
    setCallingme(true);
    if (refIsMobile.current && refStorageLib.current) {
      try {
        data.date = new Date();
        const str = JSON.stringify(data);
        refStorageLib.current.setItem('@offer', str.toString());

      } catch (error) {
        throw new Error(`Erro ao salvar offer no asyncStorage. ${error}`)
      }
      if (refCallBackCallUser.current) {
        refCallBackCallUser.current(data.from);
      }
      if (refIsMobile.current) {
        setTimeout(() => {
          acceptACall();
        }, 3000)
      }
    }
  }




  async function addCandidate(candidate) {

    if (candidate && peerConnection.current) {
      const candidates = JSON.parse(candidate)

      for (const cand of candidates) {
        try {
          if (cand && cand.candidate)
            await peerConnection.current.addIceCandidate(new refRTCIceCandidate.current(cand));
        } catch (error) {
          console.log('Error add candidate: ' + error)
        }
      }

      socket.current.emit('ice-candidate', {
        to: refCaller.current,
        candidates: JSON.stringify(candidatesToSend.current)
      });

      candidatesToSend.current = [];

      setInCall(true);
      if (refCallBackInitCall.current) {
        refCallBackInitCall.current();
      }
    }
  }



  function cleanStreams() {
    if (refLocalStream.current) {
      refLocalStream.current.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }



  function refuseCall() {
    try {
      if (peerConnection.current) peerConnection.current.close();

      cleanStreams();
      setRemoteStream(null)
      setLocalStream(null)
      setInCall(false);
      setCallingme(false);
      setConnectionState(null)
      socket.current.emit('callWasRefuse', {
        to: refCaller.current
      });
    } catch (error) {
      throw new Error('refuseCall: ' + error)
    }
  }



  function endCall() {
    if (peerConnection.current) {
      try {
        cleanStreams();
        peerConnection.current.close();
        setRemoteStream(null)
        setLocalStream(null)
        setInCall(false);
        setConnectionState(null)
        socket.current.emit('endCall', {
          to: idOther
        });
        if (refCallBackEndCall.current) {
          refCallBackEndCall.current();
        }
      } catch (error) {
        throw new Error('Erro ao finalizar chamada: ' + error)
      }
    }
  }



  async function callAnswerd(data) {
    if (!peerConnection.current) {
      await configFramesAndWebrtc();
    }

    if (data.newID)
      refIDTOCall.current = data.newID;

    peerConnection.current.setRemoteDescription(new refRTCSessionDescription.current(data.answer));

    socket.current.emit('ice-candidate', {
      to: refIDTOCall.current,
      candidates: JSON.stringify(candidatesToSend.current)
    });

    candidatesToSend.current = [];
  }



  async function acceptACall() {
    try {
      await configFramesAndWebrtc();

      setCallingme(false)

      let _dataCalling = dataCalling.current;

      if (refIsMobile.current && refStorageLib.current) {
        if (!_dataCalling) {
          _dataCalling = JSON.parse(await refStorageLib.current.getItem('@offer'));
        }
        await refStorageLib.current.removeItem('@offer');
      }

      refCaller.current = _dataCalling.from;

      setIdOther(_dataCalling.from);

      const data = _dataCalling;

      if (data.offer) {
        await peerConnection.current.setRemoteDescription(new refRTCSessionDescription.current(data.offer))

        const answer = await peerConnection.current.createAnswer();

        await peerConnection.current.setLocalDescription(answer);

        socket.current.emit("answerCall", {
          to: refCaller.current,
          answer: answer,
          myID: refMyID.current
        })
      }
      setInCall(true);
      if (refCallBackInitCall.current) {
        refCallBackInitCall.current();
      }
    } catch (error) {
      throw new Error('Erro acceptACall ' + error)
    }
  }





  //Only web
  async function takeRemotePictures(videoW, videoH, remoteVideoRef) {
    try {
      if (refIsMobile.current) return () => alert('Funcionalidade disponível apenas para a versão web');

      const blob = await getBlobFromVideo(videoW, videoH, remoteVideoRef);
      const base64 = await blobToBase64(blob)

      if (base64) return base64;

      return false;
    } catch (error) {
      throw new Error('takeRemotePictures Error: ' + error)
    }
  }





  async function readQrCodeRemote(videoW, videoH, remoteVideoRef) {
    try {
      if (!refQrCodeDecoder.current) return alert('QrCodeDecoder not injected as dependencie');

      const dataURL = await getDataUrlFromVideo(videoW, videoH, remoteVideoRef);
      const qr = new refQrCodeDecoder.current();
      const result = await qr.decodeFromImage(dataURL)
      if (result) {
        return result.data;
      }

      return false;
    } catch (error) {
      throw new Error('readQrCodeRemote Error: ' + error)
    }

  }


  async function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async function getBlobFromVideo(videoW, videoH, remoteVideoRef) {
    const canvas = document.createElement('canvas'); // create a canvas
    const ctx = canvas.getContext('2d'); // get its context
    canvas.width = videoW; // set its size to the one of the video
    canvas.height = videoH;
    ctx.drawImage(remoteVideoRef.current, 0, 0); // the video
    return new Promise((res, rej) => {
      canvas.toBlob(res, 'image/jpeg'); // request a Blob from the canvas
      // res(canvas.toDataURL())
    });
  }



  async function getDataUrlFromVideo(videoW, videoH, remoteVideoRef) {
    const canvas = document.createElement('canvas'); // create a canvas
    const ctx = canvas.getContext('2d'); // get its context
    canvas.width = videoW; // set its size to the one of the video
    canvas.height = videoH;
    ctx.drawImage(remoteVideoRef.current, 0, 0); // the video
    return new Promise((res, rej) => {
      res(canvas.toDataURL())
    });
  }



  return [bootstrap, mySocketID, remoteStream, localStream, callingme, inCall, callUser, acceptACall, endCall, readQrCodeRemote, takeRemotePictures, refuseCall, socketInstance, connectionState]
}


export default useVideoChat;

