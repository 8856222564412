import styled, { keyframes } from "styled-components"




interface IPriority {
  priority: 1 | 2 | 3,
  delay: number;
}


const animaItem = keyframes`
  to{
    opacity: 1;
    transform: translateX(0px);
  }
`


export const OverLay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,.4);
`


export const Card = styled.button<IPriority>`
  opacity: 0;
  transform: translateX(100px);
  animation-delay: ${props => props.delay}s;
  animation: ${animaItem} .2s forwards  ${props => props.delay}s;
  display: block;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
  padding: 14px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin-bottom: 4px;
  position: relative;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &:hover{
    background: #f9f9f9
  }

  &:active{
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  }

  ${({ priority, theme }) => {
    const obj = {
      1: theme.colors.primary,
      2: theme.colors.yellow,
      3: theme.colors.red
    }

    const color = obj[priority];

    return `
      &:before{
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height:100%;       
        left: 0px;
        background: ${color}
      }
      `
  }}

 

  .title{
    font-size: 1rem;
    color: ${props => props.theme.colors.black};  
    font-weight: 425;  
  }

  .dates{
    font-size: .75rem;
    color: ${props => props.theme.colors.primary};  
 
  }

  .subtitle{
    font-size: .75rem;
    color: ${props => props.theme.colors.silverChumbo};    
  }

`

export const RowColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`


export const Row = styled.div`
  display: flex;  
  align-items: center;
  justify-content: space-between;
  width: 100%;    
`



export const PhonePosition = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 6px;
  right: 6px;
`