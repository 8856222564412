import styled from "styled-components";

export const Container = styled.div`
  background: #303641;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;

  .flexContainer{
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .capitalize{
    text-transform: capitalize;
  }

  .containerTurno{
    cursor: pointer;
    background: ${props => props.theme.colors.second};
    &:hover{
      background: ${props => props.theme.colors.primary};
    }
    padding: 4px 8px;
    border-radius: 18px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${props => props.theme.colors.white};
    >span{
      font-size: .75rem;
     
    }
  }
`

export const Title = styled.h1`
  font-family: sans-serif;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 200;
`

export const LogoutButton = styled.button`
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;

  :hover{
    cursor: pointer;
  }
`