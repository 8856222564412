import { useContext } from 'react';
import AuthContext from '../contexts/auth';
import AppRouter from './app.routes';
import AuthRouter from './auth.routes';

const Rotas: React.FC = () => {
  const { isLogged } = useContext(AuthContext);
  return isLogged ? <AppRouter /> : <AuthRouter />
}

export default Rotas;