import React, { useEffect, useState } from 'react';
import { BsFillTelephoneOutboundFill, BsFillTelephoneXFill } from "react-icons/bs"
import { useTheme } from 'styled-components';
import NoData from '../nodata';
import { Container, HeaderInfoGeral, ContentInfoGeral, ItemInfoGeral, IconContainer, Row } from './styles';



interface IProps {
  chamadasOperador?: {
    chamadasDia: number;
    chamadasEfetivadas: number;
    chamadasPerdidas: number;
  }
}

const MyInfo: React.FC<IProps> = ({ chamadasOperador }) => {
  const theme = useTheme()
  const [copyData, setCopyData] = useState<any>(null);

  useEffect(() => {
    if (chamadasOperador) {
      setCopyData(chamadasOperador);
    }
  }, [chamadasOperador])



  return (
    <Container>
      <HeaderInfoGeral>
        <h2>Meus dados</h2>
        <span>números relacionados ao seu usuário</span>
      </HeaderInfoGeral>
      <ContentInfoGeral>


        {!copyData && <NoData />}

        {copyData && <>
          <ItemInfoGeral>
            <Row>
              <IconContainer>
                <BsFillTelephoneOutboundFill size={10} color={theme.colors.white} />
              </IconContainer>
              <span id={"labelItemInfoGeral"}>Ligações Hoje</span>
            </Row>
            <span id={"labelItemInfoGeralNumber"}>{chamadasOperador?.chamadasDia}</span>
          </ItemInfoGeral>



          <ItemInfoGeral>
            <Row>
              <IconContainer>
                <BsFillTelephoneOutboundFill size={10} color={theme.colors.white} />
              </IconContainer>
              <span id={"labelItemInfoGeral"}>Ligações Efetivas</span>
            </Row>
            <span id={"labelItemInfoGeralNumber"}>{chamadasOperador?.chamadasEfetivadas}</span>
          </ItemInfoGeral>


          <ItemInfoGeral>
            <Row>
              <IconContainer>
                <BsFillTelephoneXFill size={10} color={theme.colors.white} />
              </IconContainer>
              <span id={"labelItemInfoGeral"}>Ligações Perdidas</span>
            </Row>
            <span id={"labelItemInfoGeralNumber"}>{chamadasOperador?.chamadasPerdidas}</span>
          </ItemInfoGeral>
        </>}
      </ContentInfoGeral>

    </Container>
  )
}

export default MyInfo;