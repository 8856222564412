import { Container, LogoutButton, Title } from "./styles";
import { HiOutlineLogout } from 'react-icons/hi';
import { useContext, useState } from "react";
import AuthContext from "../../contexts/auth";
import TurnoContext from "../../contexts/turno";
import CallMethodContext from "../../contexts/callmethods";
import { RiExchangeFill } from "react-icons/ri"
import Options from "../options";
import { SelectOptions } from '../../@interfaces';
import packageInfo from '../../../package.json';

const Header: React.FC = () => {
  const { signOut } = useContext(AuthContext);
  const { setCurrentCalled, inCall } = useContext(CallMethodContext);
  const { turnoDescription, allTurnos, setCurrentTurno } = useContext(TurnoContext);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [dataOptions, setDataOptions] = useState<SelectOptions[]>([]);

  function handleLogout() {
    setCurrentCalled(null);
    signOut()
  }



  function openOptions() {
    const dataOptions = allTurnos.map(i => {
      return {
        label: i.descricao,
        value: i.codturno
      }
    });
    setDataOptions(dataOptions);
    setShowOptions(true);
  }



  function selectOptions(turno: any) {
    setCurrentTurno(turno);
    setCurrentCalled(null);
  }


  return (
    <>
      {showOptions && <Options itens={dataOptions} selectOption={selectOptions} close={() => setShowOptions(false)} />}
      <Container>
        <Title><strong>HK</strong> Supervisão remota <span style={{ fontSize: 12 }}>( v{packageInfo.version} )</span></Title>

        <div className="flexContainer">
          <button className="containerTurno" onClick={openOptions} disabled={inCall}>
            <RiExchangeFill />
            <span className="capitalize">{turnoDescription}</span>
          </button>
          <LogoutButton onClick={handleLogout} disabled={inCall}>
            sair
            <HiOutlineLogout size={20} />
          </LogoutButton>
        </div>
      </Container>
    </>
  )
}

export default Header;