import styled, { keyframes } from "styled-components"


interface IButton {
  primary?: boolean;
}

const animaContent = keyframes`
  from{
    opacity: 0;
    transform: translateY(-50px);
  }to{
    opacity: 1;
    transform: translateY(0);
  }
`



export const CloseButton = styled.button`
  padding: 6px;
  outline: none;
  cursor: pointer;
`


export const Button = styled.button<IButton>`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  margin: 0;
  padding: 0;
  margin-left: 20px;
  font-size: .875rem;
  ${({ primary, theme }) => {
    if (primary) {
      return `
        background: ${theme.colors.primary};
        color: ${theme.colors.white};
        padding: 4px 12px;
        border-radius: 2px;
      `
    }
  }}
`


export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0,0,0,.5);
`


export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #eee; 
`


export const Content = styled.div`
  flex: 1;
  padding: 12px 16px;

  >p{
    font-size: 16px;
    font-weight: 325;
    line-height: 24px;
    color: ${props => props.theme.colors.black}
  }
`

export const Box = styled.div`
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
  animation: ${animaContent} .7s forwards;
  opacity: 0;
  width: 500px;
  max-height: 220px;
  background: ${props => props.theme.colors.white};
  border-radius: calc(0.5rem - 1px);;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;


  @media(max-width: 524px){
    width: 450px;
  }

  @media(max-width: 467px){
    width: 400px;
  }


  @media(max-width: 420px){
    width: 350px;
  }

  @media(max-width: 3px){
    width: 350px;
  }

`


export const Item = styled.div`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
`



export const Footer = styled.div`
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 10px 10px;
 
  #addButton{
    font-weight: bold;
    color: ${props => props.theme.colors.primary}
  }

`