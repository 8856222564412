import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 20px;
`

export const Title = styled.h1``

interface IFieldProps {
  slideDirection?: 'right' | 'left'
}

export const Field = styled.input<IFieldProps>`
  border: none;
  border-bottom: 1px solid #2991D6;
  width: 250px;
  padding: 10px 0;
  outline: none;
 
  
  ::placeholder{
    color: #BABABA;
    font-family: Quicksand, sans-serif;
  }
`

export const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
`