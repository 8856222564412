import styled, { keyframes } from "styled-components";

const slideUp = keyframes`
  from{
    opacity: 0;
    transform: translateY(-50px);
  }to{
    opacity: 1;
    transform: translateY(0);
  }
  
`
interface IModalContainerProps {
  width?: string,
}
export const ModalContainer = styled.div<IModalContainerProps>`
  width: 100%;
  ${props => props.width && `width: ${props.width}`};
  animation: ${slideUp} .7s forwards;
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;  
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Header = styled.div`
  width: 100%;
  padding: 10px;  
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;
`