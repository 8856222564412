import styled from "styled-components"

interface IProps {
  background?: string;
  scale?: number;
  size?: number;

}

interface ITextProps {
  textSize?: number;
}

export const Circle = styled.div<IProps>`
  width: ${props => props.size ? `${props.size}px` : '150px'};
  height: ${props => props.size ? `${props.size}px` : '150px'};
  background: ${props => props.background ? props.background : '#cadcff'};
  border-radius: 50%;
  position: relative;
  display: grid;
  place-items: center;
  transition: all 1s;
  transform: ${props => props.scale ? `scale(${props.scale})` : 'scale(1)'};
  &:before{
    content: '';
    position: absolute;
    width: 84%;
    height: 84%;

    background: #fff;
    border-radius: 50%;
  }
`

export const Value = styled.p<ITextProps>`
  color: #000;
  position: relative;
  font-size: ${props => props.textSize ? `${props.textSize}px` : '2rem'};
`

export const Container = styled.div<ITextProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.textSize ? `${props.textSize}px` : '.75rem'};
  gap: 2px;
`