import styled from "styled-components"

interface IButtonProps {
  size: number;
  color?: string;
  notHover?: boolean
}

export const RoundButton = styled.button<IButtonProps>`
  border: none;
  width: ${props => props.size}px;
  height:${props => props.size}px;
  border-radius: 50%;
  background: ${props => props.color ? props.color : '#000'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .5s;
  :hover {
    cursor: pointer;
    ${props => !props.notHover && 'background: #2991D6;'}
    transition: all .5s;
    transform: translateY(-4px);
  }
`

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  span {
    color: ${props => props.theme.colors.darkSilver};
    font-size: 12px;
    font-weight: bold;
  }
`