import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BsFillCameraVideoFill } from 'react-icons/bs';
import { useTheme } from "styled-components"
import { ICustumer, ILocalSupervisaoRemota } from '../../../../@interfaces';
import moment from 'moment';
import ToastContext from '../../../../contexts/toast';

import {
  Container,
  Content,
  ItensContainer,
  Footer,
  Item,
  InfoCompany
} from './styles';


interface IItem {
  descricao: string;
  chave: string;
}

interface IProps {
  dataDevices: ILocalSupervisaoRemota,
  close: () => void;
  handle: (chave: string) => void;
}



const ListDevices: React.FC<IProps> = ({ close, dataDevices, handle }) => {
  const [_itens, setItens] = useState(dataDevices.equipamentos);
  const [_itensToShow, setItensToShow] = useState(dataDevices.equipamentos);
  const theme = useTheme();
  const { showErrorToast } = useContext(ToastContext);
  let statusUltimaChamada = null;


  function returnStatus(status: string) {
    return {
      1: 'Livre',
      2: 'Atendimento',
      3: 'Ronda'
    }[status]
  }



  function handleItemClick(chave: string) {

    const { horaFim, horaIni } = dataDevices.horarioSupervisao!;
    const startTime = moment(horaIni.replace('Z', '')).format("HH")
    const endTime = moment(horaFim.replace('Z', '')).format("HH")
    const now = moment().format('HH');

    if ((now >= startTime) && (now <= endTime)) {
      close();
      handle(chave);
    } else {
      showErrorToast('Fora do horário de supervisão')
    }
  }






  return (
    <Container>
      <Content>
        <InfoCompany>
          <span><strong>Ultima ligação: </strong>{dataDevices.ultimaChamada && moment(dataDevices.ultimaChamada.data.replace('Z', '')).format('DD/MM/YYYY HH:mm')}</span>
          <span><strong>Status Ultima Ligação: </strong>{returnStatus(String(dataDevices.ultimaChamada?.status))}</span>
          <span><strong>Horário de Supervisão: </strong>
            entre {moment(dataDevices.horarioSupervisao?.horaIni.replace("Z", "")).format('HH:mm')} e {moment(dataDevices.horarioSupervisao?.horaFim.replace("Z", "")).format("HH:mm")}
          </span>
        </InfoCompany>
        <ItensContainer>

          <ul>
            {_itensToShow?.map((i, index) => {
              return (
                <li key={index}>
                  <Item>
                    <div>
                      <span>{i.numeroCelular}</span>
                      <span id={"chaveLicenca"}>{i.chaveLicenca}</span>
                    </div>

                    <button >
                      <BsFillCameraVideoFill color={theme.colors.primary} size={18} onClick={() => handleItemClick(i.chaveLicenca)} />
                    </button>
                  </Item>
                </li>
              )
            })}
          </ul>
        </ItensContainer>
        <Footer>
          <button onClick={close}>Fechar</button>
        </Footer>
      </Content>
    </Container>
  )
}

export default ListDevices;

