import Header from "../components/header";
import AppWrapper from "../components/appwrapper"
import AppLayout from "../components/applayout"

const Rotas: React.FC = () => {
  return (
    <AppLayout>
      <AppWrapper />
    </AppLayout>
  )
}

export default Rotas;