import React, { useEffect, useRef, useState } from 'react';

import { Circle, Value, Container } from './styles';

interface IProps {
  progress: number,
  label?: string;
  scale?: number;
  fillColor?: string;
  backColor?: string;
  size?: number;
  textSize?: number;
}

const CircularProgress: React.FC<IProps> = ({ progress, scale, fillColor, backColor, size, textSize, label }) => {
  const [background, setBackground] = useState('');
  const [_progress, setProgress] = useState<number>(0)
  const running = useRef(false);


  useEffect(() => {
    if (progress > 100) return;
    animation()
  }, [progress])


  async function animation() {
    if (running.current) return;

    running.current = true;

    let _colorFill = `${fillColor ? fillColor : '#4d5bf9'}`;
    let _colorBack = `${backColor ? backColor : '#cadcff'}`;

    for (let i = 0; i <= progress; i++) {
      const _background = ` conic-gradient(${_colorFill} ${i * 3.6}deg, ${_colorBack} ${i * 3.6}deg);`
      setBackground(_background);
      setProgress(i);
      await sleep(50);
    }

    running.current = false;
  }



  async function sleep(timer: number) {
    return new Promise((r, _) => {
      setTimeout(() => {
        return r(true);
      }, timer)
    })
  }


  return (
    <Container>
      <Circle background={background} scale={scale} size={size}>
        <Value textSize={textSize}>{_progress}%</Value>
      </Circle>
      {label && <span>{label}</span>}
    </Container>
  );
}

export default CircularProgress;