import { ICurrentCalled, ICustumer, ISectionCall } from "../@interfaces";

const storage = {
  setLicenseToken: function (token: string) {
    try {
      sessionStorage.setItem('@hkspr:license_token', token);
    } catch {

    }
  },

  getLicenseToken: function () {
    try {
      return sessionStorage.getItem('@hkspr:license_token');
    } catch {

    }
  },



  setFullAccessToken: function (token: string) {
    try {
      sessionStorage.setItem('@hkspr:fullat', token);
    } catch {
    }
  },

  getFullAccessToken: function () {
    try {
      return sessionStorage.getItem('@hkspr:fullat');
    } catch {
    }
  },


  setChaveEx: function (chave: number) {
    try {
      sessionStorage.setItem('@hkspr:chaveEx', String(chave));
    } catch {
    }
  },

  getChaveEx: function () {
    try {
      return sessionStorage.getItem('@hkspr:chaveEx');
    } catch {
      return 0;
    }
  },


  setCurrentCustumer: function (custumer: ICustumer) {
    try {
      const data = btoa(JSON.stringify(custumer));
      sessionStorage.setItem('@hkspr:c', data);
    } catch {
    }
  },

  getCurrentCustumer: function (): ISectionCall {
    try {
      const data = sessionStorage.getItem('@hkspr:c');

      if (data) {
        const result = atob(data);
        return JSON.parse(result);
      }

      return { error: true } as ISectionCall;

    } catch {
      throw new Error('getCurrentCustumer')
    }
  },


  setClientUrl: function (url: string) {
    try {
      sessionStorage.setItem('@hkspr:client_url', url);
    } catch {

    }
  },

  getClientUrl: function () {
    try {
      return sessionStorage.getItem('@hkspr:client_url');
    } catch (e) {
      throw new Error("getClientUrl: " + e)
    }
  },

  setAccessToken: function (token: string) {
    try {
      sessionStorage.setItem('@hkspr:access_token', token);
    } catch {

    }
  },

  getAccessToken: function () {
    try {
      return sessionStorage.getItem('@hkspr:access_token');
    } catch (e) {
      throw new Error("getAcessToken: " + e)
    }
  },

  setLoggedUser: function (user: { name: string, re: number }) {
    try {
      const stringfiedUser = JSON.stringify(user)
      sessionStorage.setItem('@hkspr:logged_user', stringfiedUser);
    } catch {

    }
  },

  getLoggedUser: function () {
    try {
      const stringfiedUser = sessionStorage.getItem('@hkspr:logged_user');
      let user;
      if (stringfiedUser) user = JSON.parse(stringfiedUser);
      return user;
    } catch (e) {
      throw new Error("getLoggedUser: " + e)
    }
  },




  setLastCalled: function (lastCalled: ICurrentCalled) {
    try {
      const _str = JSON.stringify(lastCalled)
      sessionStorage.setItem('@hkspr:last_called', _str);
    } catch (e) {
      throw new Error("last_called set: " + e)
    }
  },

  getLastCalled: function () {
    try {
      const _str = sessionStorage.getItem('@hkspr:last_called');

      if (_str) return JSON.parse(_str);
      else return null;

    } catch (e) {
      throw new Error("last_called: get " + e)
    }
  },


  deleteLastCalled: function () {
    try {
      sessionStorage.removeItem('@hkspr:last_called');
    } catch (e) {
      throw new Error("last_called remove: " + e)
    }
  },


  addCheckListAnswer: function (chaveChecklist: number) {
    try {
      const currentSection: ISectionCall = this.getSectionCall();
      const currentChecks = currentSection.checklists;
      if (currentChecks) {
        currentChecks.push({ chaveChecklist });
      } else {
        currentSection.checklists = [];
        currentSection.checklists.push({ chaveChecklist });
      }

      this.setSectionCall(currentSection);
    } catch (error) {
      throw new Error("addCheckListAnswer: " + error)
    }
  },

  setSectionCall: function (secction: ISectionCall) {
    try {
      const toSave = btoa(JSON.stringify(secction));
      localStorage.setItem('@hkspr:sectionCall', toSave);
    } catch (error) {
      throw new Error("Não foi possível salvar sectionCall")
    }
  },
  cleanSection: function () {
    try {
      localStorage.removeItem('@hkspr:sectionCall')
    } catch (error) {
      throw new Error("Não foi possível salvar sectionCall")
    }
  },

  getSectionCall: function (): ISectionCall {
    try {
      const data = localStorage.getItem('@hkspr:sectionCall');
      if (data) {
        const result = atob(data);
        return JSON.parse(result);
      }
      return { error: true } as ISectionCall;
    } catch {
      throw new Error('getSectionCall')
    }
  },

  clear: function () {
    sessionStorage.clear();
    localStorage.clear();
  }

}

export default storage;