import { AiOutlineClose } from "react-icons/ai";
import { IModalProps } from "../../@interfaces";
import CloseButtoon from "../closebutton";
import { Content, Header, ModalContainer, Overlay } from "./style";

const ModalBase: React.FC<IModalProps> = ({ closeModal, children, width }) => {
  return (
    <Overlay>
      <ModalContainer width={width}>
        <Header>
          <CloseButtoon handle={closeModal} color={"#000"}/>
        </Header>
        <Content>
          {children}
        </Content>
      </ModalContainer>
    </Overlay>
  )
}

export default ModalBase;