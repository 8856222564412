import { createContext, useEffect, useState } from "react";
import storage from "../storage";

interface IContextProps {
  isLogged: boolean;
  signIn: () => void;
  signOut: () => void;
}


const AuthContext = createContext<IContextProps>({} as IContextProps);

export const AuthContextProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [isLogged, setIsLogged] = useState<boolean>(false);

  useEffect(() => {
    const logged = storage.getLoggedUser();
    if (logged) {
      setIsLogged(true);
    }
  }, [])

  function signIn() {
    // storage.setLoggedUser(email);
    setIsLogged(true);
  }

  function signOut() {
    // storage.clearAll();
    setIsLogged(false);
    storage.clear();
  }
  return (
    <AuthContext.Provider value={{
      isLogged,
      signIn,
      signOut
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;