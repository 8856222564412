import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 15px;
`

export const Question = styled.span`
  margin: 20px;
`

export const OptionsContainer = styled.ul`
  width: 100%;
`

interface IOptionProps {
  selected?: boolean
}

export const Option = styled.li<IOptionProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  transition: background .5s;
  border: 1px solid #eee;
  margin: 10px 0;
  ${props => props.selected && `
    background: #2991D6;
    color: #fff;
  `}
  :hover{
    cursor: pointer;
    background: #eee;
  }
`

export const NavOptions = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`


interface INavButtonProps {
  primary?: boolean
}

export const NavButton = styled.button<INavButtonProps>`
  ${props => props.primary && 'font-weight: bold;'}
  font-size: 16px;
  color: #303641;
  display: flex;
  align-items: center;
  :hover{
    cursor: pointer;
  }
`


export const Warning = styled.p`
  text-align: center;
  font-size: 14px;
  color: red;
`