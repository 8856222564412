export default {
  title: "defaultTheme",
  colors: {
    primary: "#6096ba",
    second: "#005387",
    silverBG: "#FAFAFA",
    white: "#fff",
    black: "rgba(0,0,0,.8)",
    darkSilver: "#303641",
    silverChumbo: "rgba(68, 75, 86, .42)",
    green: "#43aa8b",
    red: '#db3a34',
    yellow: '#e09f3e',
    redBlack: '#540b0e',
    placeHolderColor: '#999'
  },
  font: "'Quicksand', sans-serif;",
  titleSize: "1rem",
  subTitleSize: '.5rem',
}


