import { ICompanySelectionModal, IModalProps } from "../../../../@interfaces";
import ModalBase from "../../../../components/modalbase";
import { ListContainer, Option } from "./styles";

const CompanySelectionModal: React.FC<ICompanySelectionModal> = ({ closeModal, options, handleClick }) => {
  return(
    <ModalBase width="500px" closeModal={closeModal}>
      <ListContainer>
        {options.map(item => 
          <Option onClick={() => handleClick(item)}>
            {item.label}
          </Option>
        )}
      </ListContainer>
    </ModalBase>
  )
}

export default CompanySelectionModal;
