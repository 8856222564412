import styled, { keyframes } from "styled-components"

const animaContent = keyframes`
  from{
    opacity: 0;
    transform: translateY(-50px);
  }to{
    opacity: 1;
    transform: translateY(0);
  }
`

export const Container = styled.div`
  animation: ${animaContent} .7s;
  background: #303641;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 50px; 
  flex: 1;
  justify-content: space-between;
  width: 80%;
  position: absolute;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; 
`