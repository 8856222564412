import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

const useStopWatch = (): [string, () => void, () => void] => {
  const startTimer = useRef<moment.Moment>();
  const durationRef = useRef<ReturnType<typeof setInterval>>();
  const [_duration, setDuration] = useState<string>('00:00:00');

  useEffect(() => {
    return () => resetStopWatch();
  }, [])

  function startStopWatch() {
    startTimer.current = moment();
    let seconds: number = 0;

    durationRef.current = setInterval(() => {

      let s = moment().diff(startTimer.current, 'seconds')
      let minutes = String(Math.floor(s / 60));
      let hour = String(Math.floor(s / (60 * 60)))

      seconds = s % 60;

      setDuration(`${hour.padStart(2, '0')}:${minutes.padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)

    }, 500)
  }


  function resetStopWatch() {
    if (durationRef.current) clearInterval(durationRef.current);
    durationRef.current = undefined;
    setDuration('00:00:00');
  }

  return [_duration, startStopWatch, resetStopWatch];
}

export default useStopWatch;