import styled from "styled-components";


export const Button = styled.button`
  padding: 20px;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`