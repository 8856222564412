import React, { useState, useEffect } from 'react';
import { BsFillTelephoneOutboundFill, BsFillTelephoneXFill } from "react-icons/bs"
import NoData from '../nodata';

import { Container, HeaderInfoGeral, ContentInfoGeral, ItemInfoGeral, IconContainer, Row } from './styles';

interface IProps {
  chamadasGerais?: {
    chamadasDia: number;
    chamadasEfetivadas: number;
    chamadasPerdidas: number;
  }
}


const InfoGeral: React.FC<IProps> = ({ chamadasGerais }) => {
  const [copyData, setCopyData] = useState<any>(null);

  useEffect(() => {
    if (chamadasGerais) {
      setCopyData(chamadasGerais);
    }
  }, [chamadasGerais])




  return (
    <Container>
      <HeaderInfoGeral>
        <h2>Dados da equipe</h2>
        <span>números relacionados a toda equipe</span>
      </HeaderInfoGeral>
      <ContentInfoGeral>

        {!copyData && <NoData />}

        {copyData && <>
          <ItemInfoGeral>
            <Row>
              <IconContainer>
                <BsFillTelephoneOutboundFill size={10} color={'#fff'} />
              </IconContainer>
              <span id={"labelItemInfoGeral"}>Ligações Hoje</span>
            </Row>
            <span id={"labelItemInfoGeralNumber"}>{chamadasGerais?.chamadasDia}</span>
          </ItemInfoGeral>



          <ItemInfoGeral>
            <Row>
              <IconContainer>
                <BsFillTelephoneOutboundFill size={10} color={'#fff'} />
              </IconContainer>
              <span id={"labelItemInfoGeral"}>Ligações Efetivas</span>
            </Row>
            <span id={"labelItemInfoGeralNumber"}>{chamadasGerais?.chamadasEfetivadas || 0}</span>
          </ItemInfoGeral>


          <ItemInfoGeral>
            <Row>
              <IconContainer>
                <BsFillTelephoneXFill size={10} color={'#fff'} />
              </IconContainer>
              <span id={"labelItemInfoGeral"}>Ligações Perdidas</span>
            </Row>
            <span id={"labelItemInfoGeralNumber"}>{chamadasGerais?.chamadasPerdidas}</span>
          </ItemInfoGeral>
        </>}
      </ContentInfoGeral>
    </Container>
  )
}

export default InfoGeral;