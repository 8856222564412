import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;

  #noData{
    width: 100%;
    text-align:center;
    margin-top: 20px;
    >span{
      font-size: .825rem;
      color: ${props => props.theme.colors.placeHolderColor};      
    }
  }
`