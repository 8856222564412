import styled, { keyframes } from "styled-components"


const animaContent = keyframes`
  from{
    opacity: 0;
    transform: translateY(-50px);
  }to{
    opacity: 1;
    transform: translateY(0);
  }
`


export const Item = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div > span{
    font-weight: 300;
    font-size: .75rem;
  }
  >div{
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  >button{
    cursor: pointer;
  }
`


export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 60px;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0,0,0,.7); 

  #chaveLicenca{
    color: ${props => props.theme.colors.silverChumbo};
  }
`


export const InfoCompany = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding-bottom: 10px;
  background: ${props => props.theme.colors.darkSilver};
  color: #fff;
  padding: 10px;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  >span{
    font-weight: 300;
    font-size: .875rem;
    >strong{
     font-weight: 500;
   }
  }
  
`


export const Content = styled.div`
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
  animation: ${animaContent} .7s forwards;
  opacity: 0;
  width: 500px;
  height: 400px;
  
  background: ${props => props.theme.colors.white};
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  outline: 0;
  box-shadow: rgba(200, 103, 103, 0.05) 0px 0px 0px 1px;

  >h1{
    font-weight: 200;
    font-size: 1rem;
  }

  >h4{
    font-weight: 500;
    align-self: center;
    margin-bottom: 16px;
  }

  >ul{
    margin-top: 20px;
    padding: 10px;
  }

  >span{
    align-self: center;
    font-size: .875rem;
    color: rgba(0,0,0,.4)
  }

  @media(max-width: 524px){
    width: 450px;
  }

  @media(max-width: 467px){
    width: 400px;
  }


  @media(max-width: 420px){
    width: 350px;
  }

  @media(max-width: 3px){
    width: 350px;
  }

`





export const ItensContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  height: 300px;
  flex-direction: column;
  margin: 10px; 
  >ul{    
    padding: 10px;
    width: 100%;
  }
  >ul li{
    width: 100%;
    transition: all .3s;
    font-family: ${props => props.theme.font};    
    padding: 8px 2px;
    outline: 0;   
    &:after{
      display: block;
      content: "";
      width: 100%;
      height: 1px;
      background: #eee;
      margin-top: 10px;
    }
  }
`

export const Footer = styled.div`
  border-top: 1px solid #eee; 
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 45px;

  >button{
    cursor: pointer;
  }
`