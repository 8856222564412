import styled from "styled-components"

export const Container = styled.div`
  width: 100%;  
  border-radius: 8px;
   
  #labelItemInfoGeral{
    font-size: .75rem;
    font-weight: 400;
  }

  #labelItemInfoGeralNumber{
    font-size: .75rem;
    font-weight: 480;
  }
`


export const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const ContentInfoGeral = styled.div`
  display: flex;
  flex-direction: column;

  >div:last-child{
    border: none;
  }
`

export const ItemInfoGeral = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: .1px solid #eee;
  padding: 12px 6px;
`

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${props => props.theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`


export const HeaderInfoGeral = styled.div`
  width: 100%;    
  margin-bottom: 10px;
  >h2{
    font-weight: 480;
    color: ${props => props.theme.colors.black};
    font-size: 1rem;
  }
  >span{
    font-size: .675rem;
    color: ${props => props.theme.colors.silverChumbo};
  }
`