import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Container,
  Content,
  ItensContainer,
  Footer
} from './styles';



interface IProps {
  close: () => void;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Options: React.FC<IProps> = ({ close, value, onChange }) => {






  return (
    <Container>
      <Content>
        <h4>Descreva sua observação</h4>
        <ItensContainer>
          <textarea placeholder='observação ...' value={value} onChange={onChange}>

          </textarea>
        </ItensContainer>
        <Footer>
          <button onClick={close}>concluir</button>
        </Footer>
      </Content>
    </Container>
  )
}

export default Options;

