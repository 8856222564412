import Header from "../header"
import { Container } from "./styles"

interface ILayout {
  children: JSX.Element
}
const AppLayout: React.FC<ILayout> = ({ children }) => {
  return(
    <Container>
      <Header />
      {children}
    </Container>
  )
}

export default AppLayout;



