import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Container,
  Content,
  ItensContainer,
  Footer
} from './styles';


import { IOptionsProps } from '../../@interfaces';


const Options: React.FC<IOptionsProps> = ({ close, selectOption, itens, title }) => {
  const [_itens, setItens] = useState(itens);
  const [_itensToShow, setItensToShow] = useState(itens);
  const [filterValue, setFilterValue] = useState('')

  useEffect(() => {
    setItens(itens);
  }, [itens])


  function handleItemClick(value: string | number) {
    close();
    selectOption(value);

  }


  const filter = useCallback((valueFilter: any) => {
    let value = valueFilter.target.value;

    setFilterValue(value);

    if (!_itens) return [];

    if (value === '') {
      return setItensToShow(_itens);
    }

    const aux = [];

    for (let item of _itens) {
      if (item.label.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
        aux.push(item);
      }
    }

    return setItensToShow(aux);
  }, [])



  return (
    <Container>
      <Content>
        {/* <Input placeholder={"Buscar..."} isSearch colorOfBorder={"#eee"} value={filterValue} onChange={filter} /> */}
        {title ? <h4>{title}</h4> : null}
        <ItensContainer>
          <ul>
            {_itensToShow?.map((i, index) => <li key={index} onClick={() => handleItemClick(i.value)}>{i.label}</li>)}
          </ul>
        </ItensContainer>
        <Footer>
          <button onClick={close}>Fechar</button>
        </Footer>
      </Content>
    </Container>
  )
}

export default Options;

