import styled from "styled-components";

export const ListContainer = styled.ul`
  width: 100%;
  margin: 10px 0;
`

export const Option = styled.li`
  width: 100%;
  padding: 10px;
  transition: background .5s;
  border-bottom: 1px solid #eee;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  :hover{
    cursor: pointer;
    background: #eee;

  }
`