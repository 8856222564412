import React, { useState } from 'react';

import { MdKeyboardArrowDown } from "react-icons/md"
import { DropButton } from './styles';
import Options from "../options"
import { SelectOptions } from "../../@interfaces"


interface IProps {
  handleChoice?: (value: number | string) => void;
  data: SelectOptions[],
  close?: () => void;
  width?: string;
  title?: string;
}

const DropDownButton: React.FC<IProps> = ({ handleChoice, data, close, width, title }) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [dataOption, setDataOption] = useState<SelectOptions[] | null>(null);
  const [labelSelected, setLabelSelected] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<number | string>(0);


  function itenSelected(value: number | string) {
    const selected = data.filter(i => i.value === value)[0];
    setLabelSelected(selected.label);
    setSelectedValue(selected.value);

    if (handleChoice) {
      handleChoice(value);
    }
  }



  return (
    <>
      {showOptions && <Options itens={data} close={() => setShowOptions(false)} selectOption={itenSelected} />}
      <DropButton width={width} onClick={() => setShowOptions(true)} type={'button'}>
        <span>{labelSelected ? labelSelected : title}</span>
        <MdKeyboardArrowDown size={18} />
      </DropButton>
    </>
  )
}

export default DropDownButton;