import styled from "styled-components"


interface ISubtitles {
  color: string;
}

export const Container = styled.div`
  width: 100%;
  padding: 10px;    
  display: flex;
  flex-direction: column;
 

  #labelItemInfoGeral{
    font-size: .75rem;
    font-weight: 400;
  }

  #labelItemInfoGeralNumber{
    font-size: .75rem;
    font-weight: 480;
  }

  #subtitle{
    font-size: .75rem;
    color: ${props => props.theme.colors.black};
    font-weight: 350;
  }
  
`


export const ContainerContentCharts = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr;
`


export const SubtitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px; 
  align-items: center;
`


export const ChartsSubtitle = styled.div<ISubtitles>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => props.color};
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
`


export const ContainerInfos = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  align-items: flex-start;
  grid-template-columns: repeat(3, 1fr);
`

export const ChartsContainer = styled.div`  
  background: #FFF;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 10px;
  border-radius: 4px;
`

export const CardsContainer = styled.div`
  padding:10px;
  background: #FFF;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
`

export const CardsContainerGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);  
`




export const HeaderMySpace = styled.div`
  width: 100%;  
  >h2{
    font-weight: 480;
    color: ${props => props.theme.colors.black};
    font-size: 1rem;
  }
  >span{
    font-size: .675rem;
    color: ${props => props.theme.colors.silverChumbo};
  }
`


export const ContentInfoGeral = styled.div`
  display: flex;
  flex-direction: column;
`


