import styled from "styled-components";
import { IButtonStyleProps } from "../../@interfaces";

export const ButtonContainer = styled.button<IButtonStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: ${props => props.width}px;
  background: ${props => props.background ? props.background : props.theme.colors.primary};
  
  color: ${props => props.color ? props.color : '#fff'};

  ${props => props.height && `height: ${props.height}px;`}
  ${props => props.margin && `margin: ${props.margin};`}

  :hover{
    cursor: pointer;
  }
`