import styled from "styled-components";

export const Container = styled.div`
  background: #303641;
  color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

export const Title = styled.h1`
  font-size: 22px;
  margin-bottom: 5px;
`
export const CloseButton = styled.button`
  position: absolute;
  right: 20px; 
  top: 20px;

  :hover{
    cursor: pointer;
  }
`

export const SelectionLink = styled.a`
  color: #2991D6;
  font-weight: bold;
  font-size: 14px;

  :hover{
    cursor: pointer;
  }
`

export const DescriptionInput = styled.textarea`
  width: 100%;
  height: 75%;
`

export const SendButton = styled.button`
  background: #000;
  border: none;
  color: #fff;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  align-self: flex-end;
  :hover{
    cursor: pointer;
  }

`