import { useContext, useState } from "react";
import { IModalProps } from "../../../../@interfaces";
import Button from "../../../../components/button";
import ModalBase from "../../../../components/modalbase";
import AuthContext from "../../../../contexts/auth";
import ToastContext from "../../../../contexts/toast";
import APIAuth from "../../../../services/APIAuth";
import { Container, ErrorMessage, Field, Title } from "./styles";

const PasswordChange: React.FC<IModalProps> = ({ closeModal }) => {
  const [newPassword, setNewPassword] = useState<string>();
  const [newPasswordRep, setNewPasswordRep] = useState<string>();
  const [error, setError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>();

  const { showSuccessToast, showErrorToast } = useContext(ToastContext);

  


  function handleNewPasswordChange(password: string){
    setError('');
    setNewPassword(password);
  }




  function handleNewPasswordRepChange(password: string){
    setError('');
    setNewPasswordRep(password);
  }




  function validValues(){
    if(!newPassword || !newPasswordRep){
      setError("Preencha todos os campos.")
      return false;
    }

    return true;
  }




  async function handleSubmit(){
    if(!validValues()) return;

    setIsSubmitting(true);
    try{
      const response = await APIAuth.updatePassword({ novasenha: window.btoa(newPassword!), confirmacaonovasenha: window.btoa(newPasswordRep!)});
      if(!response.error){
        showSuccessToast("Senha alterada com sucesso.")
        closeModal();
      }else{
        setError(response.message);
      }
    }catch{
      showErrorToast('Algo deu errado.');
    }finally{
      setIsSubmitting(false);
    }
  }




  return(
    <ModalBase closeModal={closeModal} width={"400px"}>
      <Container>
        <Title>Troca de senha</Title>
        <Field placeholder="Digite a nova senha" type={"password"} value={newPassword} onChange={(e) => handleNewPasswordChange(e.target.value)}/>
        <Field placeholder="Repita a nova senha" type={"password"} value={newPasswordRep} onChange={(e) => handleNewPasswordRepChange(e.target.value)}/>
        {!!error && <ErrorMessage>{error}</ErrorMessage>}
        <Button label="Enviar" isLoading={isSubmitting!} width={250} onClick={handleSubmit}/>
      </Container>
    </ModalBase>
  )  
}

export default PasswordChange;