import React from 'react';

import { Container } from './styles';

interface IProps {
  children: React.ReactNode
}

const ContainerActions: React.FC<IProps> = (props) => {
  return (
    <Container>
      {props.children}
    </Container>
  );
}

export default ContainerActions;