import styled, { keyframes } from "styled-components"


const animaContent = keyframes`
  from{
    opacity: 0;
    transform: translateY(-50px);
  }to{
    opacity: 1;
    transform: translateY(0);
  }
`

interface IIconProps {
  selected: boolean
}


export const RaitingButton = styled.button<IIconProps>`
  outline: none;
  color: ${props => props.selected ? props.theme.colors.yellow : props.theme.colors.white};
  cursor: pointer;
  transition: all .2s;
  
  &:active{
    transform: scale(2)
  }
`

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 450px;
`

export const RatingBox = styled.div`
  animation: ${animaContent} .7s forwards;
  background: #303649;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  /* gap: 50px; */
  flex: 1;
  justify-content: space-between;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  >h2{
    align-self: center;
    font-weight: 400;
  }
`

export const StarsContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 10px;
  align-items: center;
  justify-content: center; 
  margin: 10px 0;
`

export const TextArea = styled.textarea.attrs({
  placeholder: 'Digite aqui suas observações'
})`
  width: 100%;
  border-radius: 4px;
  height: 120px;
  resize: none;
  padding: 10px;
  outline: none;
  margin-bottom: 10px;
  &::placeholder{
    color: #999;
  }
`