import React, { memo, useContext, useEffect, useState } from 'react';


import TurnoContext from '../../contexts/turno';
import InfoGeral from '../infogeral';
import MyInfo from "../myinfo"
import { useTheme } from "styled-components"
import {
  Container,
  HeaderMySpace,
  ChartsContainer,
  ContainerContentCharts,
  CardsContainerGrid,
  ContainerInfos,
  SubtitlesContainer,
  ChartsSubtitle,
  Row
} from './styles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';
import ApiUser from '../../services/ApiUser';
import NoData from "../nodata"
import DashContext from "../../contexts/dash"

ChartJS.register(ArcElement, Tooltip, Legend);



const MySpace: React.FC = () => {
  const theme = useTheme();
  const { turnoValue } = useContext(TurnoContext);
  const { dataDash, getDataDash } = useContext(DashContext);



  useEffect(() => {
    if (turnoValue) {
      getDataDash();
    }
  }, [turnoValue])



  return (
    <Container>
      <ContainerInfos>
        <ChartsContainer>
          <HeaderMySpace>
            <h2>Média mensal de ligações</h2>
          </HeaderMySpace>

          {(dataDash && !dataDash.charts) && <NoData />}

          {(dataDash && dataDash.charts) && <ContainerContentCharts>
            <div>
              <Doughnut data={dataDash.charts} />
            </div>
            <SubtitlesContainer>
              <Row>
                <span id={'subtitle'}>até 5min.</span>
                <ChartsSubtitle color={theme.colors.primary} />
              </Row>
              <Row>
                <span id={'subtitle'}>até 15min.</span>
                <ChartsSubtitle color={theme.colors.green} />

              </Row>
              <Row>
                <span id={'subtitle'}>até 30min.</span>
                <ChartsSubtitle color={theme.colors.yellow} />
              </Row>
              <Row>
                <span id={'subtitle'}>até 60min.</span>
                <ChartsSubtitle color={theme.colors.red} />
              </Row>
              <Row>
                <span id={'subtitle'}>+60min.</span>
                <ChartsSubtitle color={theme.colors.redBlack} />
              </Row>
            </SubtitlesContainer>
          </ContainerContentCharts>}
        </ChartsContainer>


        <ChartsContainer>
          <MyInfo chamadasOperador={dataDash?.chamadasOperador} />
        </ChartsContainer>

        <ChartsContainer>
          <InfoGeral chamadasGerais={dataDash?.chamadasGerais} />
        </ChartsContainer>

      </ContainerInfos>
    </Container>
  )
}

export default memo(MySpace);