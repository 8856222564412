import React from 'react';


import { Button } from './styles';
import { IoIosClose } from "react-icons/io"

interface IProps {
  handle: () => void;
  color?: string;
}

const CloseButtoon: React.FC<IProps> = ({ handle, color }) => {
  return (
    <Button onClick={handle}>
      <IoIosClose size={28} color={color ? color : "#fff"} />
    </Button>
  )
}

export default CloseButtoon;