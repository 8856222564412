import styled, { keyframes } from "styled-components";


interface IlistDevices {
  open?: boolean;
}


interface IOrderButton {
  active: boolean;
}


const callAnimation = keyframes`
  0%{
    opacity: 0;
  }50%{
    opacity: .5;
  }
  100%{
    opacity: 1;
  }
`


export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: 52px;
  width: 100%;
  position: relative;
  
  .buttonOptions{
    font-size: .75rem;
    color: ${props => props.theme.colors.primary}; 
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 10px 0 4px;
    margin: 0;
  }

  #noData{
    width: 100%;
    text-align:center;
    margin-top: 20px;
    >span{
      font-size: .825rem;
      color: ${props => props.theme.colors.placeHolderColor};      
    }
  }

  .stateCall{
    color: #fff;
    font-size: 1rem;
  }

  .animationState{
    animation: ${callAnimation} 1s linear infinite;
  }

`



export const CallingOverlay = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  z-index: 99999;
  background: rgba(0,0,0,.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  >span{
    color: #fff;
    font-size: 1.125rem;
    margin-top: 20px;
  }  
`

export const ContainerRefresh = styled.div`  
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`

export const Ul = styled.ul`
  overflow: hidden;
  height:calc(100vh - 165px);
  overflow-y: auto;
  padding: 6px;   
`

export const ContainerOrder = styled.div`
  >h2{
    font-weight: 450;
    font-size: .875rem;
    margin-bottom:10px;
  }
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 15px -3px;
  padding: 10px;

`


export const GridOrder = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6px;
`


export const OrderButton = styled.button<IOrderButton>`
  padding: 4px;
  border-radius: 18px;
  background: rgba(68, 75, 86, .1);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border:none;
  color:rgba(0,0,0,.8);
  cursor: pointer;
  font-size: .75rem;
  font-weight: 470;
  display: flex;
  justify-content: space-around;
  transition:all .2s;
  &:hover{
    background: #303641;
    color: #fff
  }
  >span{
    color: #fff
  }


  ${({ active }) => {
    if (active) return `background: #303641;color: #fff`
  }}

`





export const SideContainer = styled.div`
  box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.25);
  position: relative;
`






export const SearchInput = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  padding: 10px;


  outline: none;
`




const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`




export const ListDevices = styled.div<IlistDevices>`  
  border-top: 1px solid rgba(0,0,0,.1);
  flex-direction: column; 
  overflow: hidden;
  transition: all .3s;
  margin: 0;
  display: none;
  ${({ open }) => {
    if (open) {
      return `display: flex; transition: all .3s;`
    }
  }}
`






export const Content = styled.div` 
  background: #F1F1F1; 
`

