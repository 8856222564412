import styled, { keyframes } from "styled-components";

interface IActionButton {
  shake?: boolean;
}

const shakeAnimation = keyframes`
  from{
  
    transform: translateX(10px);
  }to{

    transform: translateX(0px);
  }`



export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 10px;
  gap: 20px;
`


export const TitleContainer = styled.div`
  position: relative;
  > div {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`
export const CloseButton = styled.button`
  position: absolute;
  right: 0; 
  top: 0;

  :hover{
    cursor: pointer;
  }
`

export const DetailText = styled.div`
  font-size: 12px;
  padding: 10px 0;
`

export const Title = styled.h1`
  font-size: 18px;
  color: ${props => props.theme.colors.white};
`

export const SubTitle = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.white};
`

export const QuestionContainer = styled.div`
  width: 50%;
  align-self: center;
  display: flex;  
  flex-direction: column;
  position: relative;
`

export const HelpContainer = styled.div`
  position: absolute;
  bottom: 100px;
  right: 28px;
`

export const ContentQuestions = styled.div`
  flex-direction: column;
  max-height: 350px;
  overflow-y: auto;
  width: 100%;
`

export const Question = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
  line-height: 24px;
  color:${props => props.theme.colors.white};
`


interface IAnswerProps {
  selected?: boolean
}

export const Answer = styled.button<IAnswerProps>`
  background: #fff;
  cursor: pointer;
  color: #000;
  ${props => props.selected && `
    background: #2991D6;
    color: #fff;
  `}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  transition: all .3s;

`

export const TextAnswer = styled.input.attrs({
  placeholder: "Digite aqui sua resposta",
  multiline: true,
  textAlignVertical: 'center',
  placeholderTextColor: 'rgba(255,255,255,.7)'
})`
  width: 100%;
  
  border-width: 1;
  border-color: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-top : 10px;
  border: none;
  outline: none;
`


export const ActionsContainer = styled.div`
  align-self: center;
  display: flex;
  gap: 20px;

`

interface IActionProps {
  primary?: boolean,
  green?: boolean;
}

export const Action = styled.button<IActionProps>`
  color: #fff;
  padding: 6px;
  transition: all .3s;
  ${props => props.primary && `
    background: ${props.theme.colors.primary};
    color: #fff;
    border-radius: 4px;
    :hover{
      background:${props.theme.colors.second};
    }
  `}
  :hover{
    cursor: pointer;
  };

  ${({ green, theme }) => {
    if (green) return `
      background:${theme.colors.green};
      :hover{
        background:${theme.colors.green};
      }
    `;
  }}

`

export const RowContainer = styled.div`
  width: 160px;
  display: flex;
  justify-content: space-around;
  align-items: center;  
`


export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(0,0,0,.2);
  padding: 16px;
  border-radius: 30px;

  .shakeClass{   
    animation: ${shakeAnimation} .1s infinite;
  }
`

export const ImagesContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 0;
  img {
    width: 25px;
    height: 25px;
  }
  >span{
    font-size: 10px;
    color: #999;
  }
`

export const ActionButton = styled.button<IActionButton>`
  width: 35px;
  height: 35px;
  background: rgba(0,0,0,.7);
  border-radius: 50%;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
 
  :hover{
    background: rgba(0,0,0,.4);
  }


  
  
`

export const ToolTipContainer = styled.div`

  align-self: flex-end;
  position: relative;
  margin-top: 6px;
  
  :hover span{
    transition: all .7s;
    display: block;
  }
`
export const ToolTip = styled.span`
  display: none;
  width: 200px;
  line-height: 20px;
  background-color: rgba(255,255,255, .8);
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 14px;
  padding: 10px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 100%;
  cursor: pointer;
  transition: all .7s;
`